import React, { useContext, useEffect, useState } from 'react'
import { GridItem } from '../../components/common/grid/GridItem'
import { UseFormReturn } from 'react-hook-form'
import { Api, Demand, DemandStatus, Law, StatusImport } from '../../api/schemas/schema'
import { t } from '../../i18n/i18n'
import { Button, makeStyles, tokens } from '@fluentui/react-components'
import { archiveDemand, deleteDemandAndResetLaw, isDemandFormDisabled, SelectedDemandModal } from '../../helpers/demandHelper'
import { DataContext } from '../../App'
import { BasicAccordion } from '../../components/common/accordion/BasicAccordion'
import { Modal } from '../../components/common/modal/Modal'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router-dom'
import { DemandForm } from './DemandForm'
import { LawChangeMessageBar } from '../../components/demand/LawChangeMessageBar'
import { SelectedDemandActions } from '../../components/demand/SelectedDemandActions'

const useStyles = makeStyles({
   midRigth: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center' },
   messageBar: { paddingTop: tokens.spacingVerticalSNudge, paddingBottom: tokens.spacingVerticalSNudge, marginBottom: tokens.spacingVerticalXS },
})

type SelectedDemandProps = {
   setOriginDemand: React.Dispatch<React.SetStateAction<Demand>>
   originDemand: Demand
   demandForm: UseFormReturn<Demand, any, undefined>
   grandParentId: string
   setBread: (parentId: string) => Promise<void>
   hasQuestions?: boolean
}

export const SelectedDemand = ({ setOriginDemand, originDemand, demandForm, grandParentId, setBread, hasQuestions }: SelectedDemandProps) => {
   const dataContext = useContext(DataContext)

   const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
   const { watch } = demandForm
   const classes = useStyles()
   const { accounts } = useMsal()
   const [openModal, setOpenModal] = useState<SelectedDemandModal>()
   const apiInstance = new Api({ baseUrl: process.env.REACT_APP_API_URL })
   const [connectedLaw, setConnectedLaw] = useState<Law>()

   useEffect(() => {
      ;(async () => {
         const law = (await apiInstance.api.lawDemandIdDetail(originDemand.id)).data
         setConnectedLaw(law)
      })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [originDemand.id])
   const navigate = useNavigate()

   const modalButtonsDisabled = openModal === null
   const isDisabled = isDemandFormDisabled(watch())
   return (
      <>
         <GridItem size="12/12">
            <BasicAccordion
               className={classes.midRigth}
               headerText={`${t('Demand')} - ${watch('designation')}`}
               messageBar={
                  connectedLaw && connectedLaw.statusImport !== StatusImport.Empty ? <LawChangeMessageBar setLaw={setConnectedLaw} law={connectedLaw} /> : null
               }
               actions={
                  <SelectedDemandActions
                     demandForm={demandForm}
                     setOpenModal={setOpenModal}
                     setIsSubmitting={setIsSubmitting}
                     grandParentId={grandParentId}
                     deleteBtnDisabled={hasQuestions || originDemand.children.length > 0}
                  />
               }
            >
               <DemandForm
                  demandForm={demandForm}
                  setOriginDemand={setOriginDemand}
                  setOpenModal={setOpenModal}
                  setIsSubmitting={setIsSubmitting}
                  setBread={setBread}
                  originDemand={originDemand}
                  openModal={openModal}
                  isDisabled={isDisabled}
                  isSubmitting={isSubmitting}
               />
            </BasicAccordion>
         </GridItem>

         <Modal
            open={openModal === SelectedDemandModal.DELETE}
            title={t('Warning')}
            actions={
               <>
                  <Button
                     onClick={async () => await deleteDemandAndResetLaw(watch(), dataContext, setIsSubmitting, accounts[0].name, grandParentId, navigate)}
                     appearance="primary"
                     disabled={isSubmitting}
                     style={{ backgroundColor: tokens.colorStatusDangerForeground1 }}
                  >
                     {t('Delete')}
                  </Button>
                  <Button onClick={() => setOpenModal(null)}>{t('Cancel')}</Button>
               </>
            }
            children={t('DemandDeleteWarningText')}
         />
         <Modal
            open={openModal === SelectedDemandModal.ARCHIVE}
            title={t('Warning')}
            actions={
               <>
                  <Button onClick={() => setOpenModal(null)}>{t('Cancel')}</Button>
                  <Button
                     onClick={async () => await archiveDemand(watch(), dataContext, setIsSubmitting, accounts[0].name, grandParentId, setOpenModal)}
                     appearance="primary"
                     disabled={isSubmitting || modalButtonsDisabled}
                  >
                     {t('Continue')}
                  </Button>
               </>
            }
            children={watch('status') === DemandStatus.Archived ? t('DemandActivateWarningText') : t('DemandArchiveWarningText')}
         />
      </>
   )
}
