import React from 'react'
import { DemandRowContent } from './DemandRowContent'
import { Demand } from '../../api/schemas/schema'
import { useContext } from 'react'
import { DataContext } from '../../App'
import { demandColumns } from '../../helpers/columnHelper'
import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { motion } from 'framer-motion'
const useStyles = makeStyles({
   row: {
      display: 'flex',
      gap: tokens.spacingVerticalS,
      boxSizing: 'border-box',
      ...shorthands.borderBottom(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStroke2),
      ...shorthands.padding(tokens.spacingVerticalS, 0),
      '&:hover': {
         backgroundColor: tokens.colorNeutralBackground1Hover,
      },
      '&:active': {
         backgroundColor: tokens.colorNeutralBackground1Pressed,
      },
   },
})
type DemandRowProps = {
   demands: Demand[]
   demand: Demand
   loadingRows: string[]
   showButton: string
   expandAllDemands: boolean
   setShowButton: (value: React.SetStateAction<string>) => void
   toggleRowExpansion: (demandId: string) => void
   parent?: Demand
}

export const DemandRow = ({
   demands,
   demand,
   loadingRows,
   showButton,
   expandAllDemands,
   setShowButton,
   toggleRowExpansion,
   parent,
}: DemandRowProps): JSX.Element => {
   const classes = useStyles()

   const dataContext = useContext(DataContext)
   const { expandedDemands } = dataContext.state
   const isExpanded = (demandId: string) => {
      return expandedDemands.some((expandedDemand) => expandedDemand === demandId)
   }
   return (
      <>
         <motion.div layout onMouseEnter={() => setShowButton(demand.id)}>
            <DemandRowContent
               toggleRowExpansion={toggleRowExpansion}
               item={demand}
               parent={parent}
               expanded={isExpanded(demand.id) ?? false}
               columns={demandColumns()}
               rowClass={classes.row}
               setShowButton={setShowButton}
               showButton={showButton}
               expanding={loadingRows.some((x) => x === demand.id)}
               items={demands}
               expandAllDemands={expandAllDemands}
            />
         </motion.div>
         {isExpanded(demand.id) && (
            <motion.div layout>
               {isExpanded(demand.id) &&
                  demand.children.map((child) => (
                     <DemandRow
                        key={child.id}
                        demands={demands}
                        demand={child}
                        loadingRows={loadingRows}
                        showButton={showButton}
                        expandAllDemands={expandAllDemands}
                        setShowButton={setShowButton}
                        toggleRowExpansion={toggleRowExpansion}
                        parent={demand}
                     />
                  ))}
            </motion.div>
         )}
      </>
   )
}
