import { IDataContext } from '../interfaces/IDataContext'
import { IRootState } from '../interfaces/IRootState'

export const initialDataContext: IDataContext = {
   state: null,
   setRootState: null,
   handleMessage: null,
}
export const GetInitialRootState = (): IRootState => ({
   navigationExpanded: false,
   isLoading: false,
   message: null,
   demands: null,
   selectedDemands: [],
   subjects: [],
   organisationSizes: [],
   organisationTypes: [],
   leadershipPerspectives: [],
   snis_2007: [],
   snis_2025: [],
   initialLoading: true,
   processes: [],
   answerOptions: null,
   additionalModules: [],
   subscriptions: [],
   selectedTab: 'activeDemands',
   expandedRows: {},
   expandedDemands: [],
   moveDemands: false,
})
