import React, { useContext, useEffect, useState } from 'react'
import { TableColumnDefinition, makeStyles, Option, Field, Dropdown, Button, tokens, Divider } from '@fluentui/react-components'
import { t } from '../i18n/i18n'
import { List } from '../components/common/list/List'
import { Page } from './Page'
import { useSearchParams } from 'react-router-dom'
import { Grid } from '../components/common/grid/Grid'
import { GridItem } from '../components/common/grid/GridItem'
import { DataContext } from '../App'
import { useMsal } from '@azure/msal-react'
import { enumValuesToOptions, enumValuesToTranslate } from '../helpers/enumHelper'
import { getLawColumns, onFilterReset, sortColumntOptions } from '../helpers/lawHelper'
import { Law, LawPaginatedList, LawStatus, StatusImport } from '../api/schemas/schema'
import { Title } from '../components/common/texts/Title'
import { ArrowClockwiseRegular } from '@fluentui/react-icons'
import { NoLawsFoundMessageBar } from '../components/law/NoLawsFoundMessageBar'
import { LargeCounterBadge } from '../components/common/LargeCounterBadge/LargeBadge'
import { setLoading } from '../helpers/stateHelper'
import { GetFilteredLaws } from '../api/law'

const useStyles = makeStyles({
   flexBtw: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: tokens.spacingHorizontalXL,
   },
   flexCenter: {
      display: 'flex',
      alignItems: 'center',
   },
   columnIconSize: {
      fontSize: tokens.fontSizeBase300,
      marginLeft: tokens.spacingVerticalSNudge,
   },
   cursor: {
      cursor: 'pointer',
   },
   flexEnd: {
      display: 'flex',
      justifyContent: 'end',
      gap: tokens.spacingHorizontalXL,
   },
   flexItemStretch: {
      flexGrow: 1,
   },
   mt0: {
      marginTop: 0,
   },
})
export const Laws = () => {
   const dataContext = useContext(DataContext)
   const { setRootState } = dataContext
   const classes = useStyles()
   const [columns, setColumns] = useState<TableColumnDefinition<Law>[]>([])
   const [statusFilter, setStatusFilter] = useState<string[]>([])
   const [importStatusFilter, setImportStatusFilter] = useState<string[]>([])
   const [columnSort, setColumnSort] = useState<string>('')
   const [searchParams, setSearchParams] = useSearchParams()
   const page = parseInt(searchParams.get('page'))
   const { accounts } = useMsal()
   const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
   const pageSize = 100
   const [paginatedLawList, setPaginatedLawList] = useState<LawPaginatedList>({ pageIndex: 1, totalItems: undefined, totalPages: undefined, items: [] })
   const { totalItems, totalPages, items } = paginatedLawList
   const hasActiveFilter = importStatusFilter.length > 0 || statusFilter.length > 0 || columnSort !== ''
   const sortOn = columnSort !== '' ? columnSort : 'SfsNr'
   useEffect(() => {
      setColumns(
         getLawColumns(
            setRootState,
            accounts[0],
            sortOn,
            sortOrder,
            classes,
            setPaginatedLawList,
            toggleSortOrder,
            statusFilter,
            importStatusFilter,
            page,
            pageSize
         )
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [columnSort, importStatusFilter, page, sortOn, sortOrder, statusFilter])
   const toggleSortOrder = () => {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'))
   }
   useEffect(() => {
      if (!searchParams.get('page')) setSearchParams({ page: '1' })
      else if (parseInt(searchParams.get('page')) > totalPages) setSearchParams({ page: totalPages > 0 ? totalPages.toString() : '1' })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [page, searchParams, setSearchParams])
   useEffect(() => {
      if (typeof page === 'number' && !isNaN(page)) {
         ;(async () => {
            const { setRootState } = dataContext
            setLoading(setRootState, true)
            const sortOn = columnSort !== '' ? columnSort : 'SfsNr'
            try {
               let result = await GetFilteredLaws(statusFilter, importStatusFilter, sortOn, sortOrder, page, pageSize)

               setPaginatedLawList(result)
            } catch (error) {
            } finally {
               setLoading(setRootState, false)
            }
         })()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [page, setRootState, statusFilter, importStatusFilter, columnSort, sortOrder])

   const handleStatusOptionSelect = (event: any, data: any) => {
      setStatusFilter(data.selectedOptions)

      setSearchParams((params) => {
         params.set('page', '1')
         return params
      })
   }
   const handleImportStatusOptionSelect = (event: any, data: any) => {
      setImportStatusFilter(data.selectedOptions)

      setSearchParams((params) => {
         params.set('page', '1')
         return params
      })
   }
   const enumStatusTranslation = (number: number): string => {
      if (number < 100) return enumValuesToTranslate(LawStatus, number)
      return enumValuesToTranslate(StatusImport, number)
   }

   return (
      <Page header={<Title as="h2">{t('LawsImport')}</Title>}>
         <Grid>
            <GridItem size="2/12">{/* <SearchField placeHolder={t('Search')} /> */}</GridItem>
            <GridItem size="10/12" className={classes.flexBtw}>
               <Field className={classes.flexItemStretch}>
                  <Dropdown
                     multiselect
                     placeholder={t('FilterOnProcessStatus')}
                     onOptionSelect={handleStatusOptionSelect}
                     selectedOptions={statusFilter ?? statusFilter}
                     value={statusFilter ? statusFilter.map((x) => enumStatusTranslation(Number(x))).join(', ') : ''}
                  >
                     {enumValuesToOptions(LawStatus).map((option) => (
                        <Option key={option.key.toString()} value={option.key.toString()}>
                           {option.text}
                        </Option>
                     ))}
                  </Dropdown>
               </Field>
               <Field className={classes.flexItemStretch}>
                  <Dropdown
                     multiselect
                     placeholder={t('FilterOnImportStatus')}
                     onOptionSelect={handleImportStatusOptionSelect}
                     selectedOptions={importStatusFilter ?? importStatusFilter}
                     value={importStatusFilter ? importStatusFilter.map((x) => enumStatusTranslation(Number(x))).join(', ') : ''}
                  >
                     {enumValuesToOptions(StatusImport).map((option) => (
                        <Option key={option.key.toString()} value={option.key.toString()}>
                           {option.key === StatusImport.Empty ? t('EmptyValue') : option.text}
                        </Option>
                     ))}
                  </Dropdown>
               </Field>
               <Field className={classes.flexItemStretch}>
                  <Dropdown
                     key={columnSort}
                     placeholder={t('SortOnColumn')}
                     onOptionSelect={(e, selectedColumn) => {
                        setSortOrder('asc')
                        setColumnSort(selectedColumn.optionValue)
                     }}
                     value={columnSort !== '' ? sortColumntOptions.find((x) => x.value === columnSort).text : ''}
                     selectedOptions={[columnSort !== '' ? columnSort : 'SfsNr']}
                  >
                     {sortColumntOptions.map((option) => (
                        <Option key={option.value} value={option.value}>
                           {option.text}
                        </Option>
                     ))}
                  </Dropdown>
               </Field>
               <Button
                  icon={<ArrowClockwiseRegular />}
                  appearance="outline"
                  onClick={() => onFilterReset(setImportStatusFilter, setStatusFilter, setColumnSort)}
                  disabled={!hasActiveFilter}
               >
                  {t('ResetFilter')}
               </Button>
               <LargeCounterBadge
                  number={totalItems}
                  text={t(hasActiveFilter ? 'LawsWithFilter' : 'LawsWithoutFilter')}
                  loading={dataContext.state.isLoading}
               />
            </GridItem>
            <GridItem size="12/12">
               <div className={classes.flexBtw}>
                  <Title as="h3" className={classes.mt0}>
                     {t('ImportedLaws')}
                  </Title>
               </div>
               <Divider appearance="brand" />
            </GridItem>
            <GridItem size="12/12">
               <List
                  pagination={items && items.length > 0 ? true : false}
                  numberOfPages={totalPages}
                  numberOfLaws={totalItems}
                  columns={columns}
                  items={items}
                  loading={dataContext.state.isLoading}
                  asCard
                  pageSize={pageSize}
               />
               {items.length === 0 && (importStatusFilter.length > 0 || statusFilter.length > 0) && <NoLawsFoundMessageBar />}
            </GridItem>
         </Grid>
      </Page>
   )
}
