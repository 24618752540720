import React from 'react'
import { BasicCard } from '../../components/common/card/BasicCard'
import { t } from '../../i18n/i18n'
import { makeStyles, tokens, Text } from '@fluentui/react-components'
import { DashboardState } from '../Dashboard'
import { BookDatabaseRegular, DocumentAdd24Regular, DocumentDismiss24Regular, DocumentSync24Regular, People48Regular } from '@fluentui/react-icons'
import dayjs from 'dayjs'
import { GridItem } from '../../components/common/grid/GridItem'
const useStyles = makeStyles({
   sideBar: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalXXXL,
   },
   numberOf: {
      color: tokens.colorPaletteMarigoldForeground1,
   },
   lawStatus: {
      display: 'flex',
      gap: tokens.spacingHorizontalM,
      backgroundColor: tokens.colorNeutralBackground1,
      borderRadius: tokens.borderRadiusXLarge,
      boxShadow: tokens.shadow8,
      color: tokens.colorNeutralForeground1,
      alignItems: 'baseline',
      padding: tokens.spacingHorizontalM,
   },
   green: {
      color: tokens.colorStatusSuccessForeground1,
   },
   red: {
      color: tokens.colorStatusDangerForeground1,
   },
   yellow: {
      color: tokens.colorPaletteMarigoldForeground3,
   },
   lawStatusContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: tokens.spacingVerticalL,
   },
})
type DashboardSidebarProps = {
   dashboardState: DashboardState
}
export const DashboardSidebar = ({ dashboardState }: DashboardSidebarProps) => {
   const classes = useStyles()
   return (
      <GridItem size="3/12" className={classes.sideBar}>
         <BasicCard
            title={t('StatusLaws')}
            color="primary"
            description={`${t('LatestUpdate')}: ${dayjs(dashboardState.lawImportStats.latestUpdate).format('YYYY-MM-DD')}`}
            alignItems="stretch"
            className={classes.lawStatusContainer}
         >
            <span className={classes.lawStatus}>
               <DocumentAdd24Regular className={classes.green} />
               <Text size={500} weight="bold">
                  {dashboardState.lawImportStats.numberOfNewLaws}
               </Text>
               <Text size={400}>{t('new')}</Text>
            </span>
            <span className={classes.lawStatus}>
               <DocumentDismiss24Regular className={classes.red} />

               <Text size={500} weight="bold">
                  {dashboardState.lawImportStats.numberOfUpdatedLaws}
               </Text>
               <Text size={400}>{t('revoked')}</Text>
            </span>
            <span className={classes.lawStatus}>
               <DocumentSync24Regular className={classes.yellow} />
               <Text size={500} weight="bold">
                  {dashboardState.lawImportStats.numberOfRevokedLaws}
               </Text>
               <Text size={400}>{t('updated')}</Text>
            </span>
         </BasicCard>
         <BasicCard title={t('NumberOfCustomers')} color="primary" alignItems="center">
            <People48Regular />
            <Text weight="semibold" size={900} className={classes.numberOf}>
               {dashboardState.numberOfCustomers}
            </Text>
         </BasicCard>
         <BasicCard title={t('NumberOfQuestions')} color="primary" alignItems="center">
            <BookDatabaseRegular fontSize={48} />
            <Text weight="semibold" size={900} className={classes.numberOf}>
               {dashboardState.numberOfQuestions}
            </Text>
         </BasicCard>
      </GridItem>
   )
}
